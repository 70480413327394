<template>
  <div class="pageNotFound">
    <v-container
      class="search-bg bg d-flex justify-center flex-column align-center pnf"
    >
      <v-img
        alt="100 Miles contact-us"
        class="mr-2 shrink justify-center"
        contain
        src="../assets/404pnf.jpg"
        transition="scale-transition"
        max-width="384"
      />
      <h2 class="my-6 blue--text">Page not found</h2>
      <v-btn to="/" color="primary" rounded>
        <v-icon left> fas fa-arrow-left</v-icon>
        Go Back To Home
      </v-btn>
    </v-container>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.pnf {
  height: 70vh;
}
</style>
